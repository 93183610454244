let setting = JSON.parse(localStorage.getItem('brand_setting'))

const state = {
    Theme: "light", // theme can be light or dark
    LayoutType: "full-sidebar", // this can be full-sidebar, mini-sidebar
    Sidebar_drawer: false,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    navbarColor: "primary",
    logoColor: "white",
    setHorizontalLayout: false, // Horizontal layout
    Direction: "ltr", // possible value  rtl / ltr
    primaryColor: setting?.themes?.primary_color || "#19cbac",
    secondaryColor: setting?.themes?.secondary_color || "#067f6d",
    primaryColorRGB: "",
    secondaryColorRGB: "",
    width_inner: window.innerWidth,
    mobile_width: 768
}

const hexToRgb = (hex) => {
    const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        , (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16));

    return {
        rgbArray: rgb,
        rgbText: `rgb(${rgb.join(', ')})`
    }
}

const mutations = {
    SET_THEME(state, payload) {
        state.Theme = payload;
    },
    SET_LAYOUT_TYPE(state, payload) {
        state.LayoutType = payload;
    },
    SET_SIDEBAR_DRAWER(state, payload) {
        state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
        state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
        state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
        state.navbarColor = payload;
    },
    SET_LOGO_COLOR(state, payload) {
        state.logoColor = payload;
    },
    SET_LAYOUT(state, payload) {
        state.setHorizontalLayout = payload;
    },
    SET_DIRECTION(state, payload) {
        state.Direction = payload;
    },
    SET_PRIMARY_COLOR(state, color) {
        state.primaryColor = color;
    },
    SET_SECONDARY_COLOR(state, color) {
        state.secondaryColor = color;
    },
    SET_PRIMARY_COLOR_ARRAY(state, color) {
        state.primaryColorRGB = color;
    },
    SET_SECONDARY_COLOR_ARRAY(state, color) {
        state.secondaryColorRGB = color;
    },
    SET_WIDTH_INNER(state, width) {
        state.width_inner = width
    }
}
const actions = {
    setLayoutType({commit}, width) {
        commit("SET_LAYOUT_TYPE", width);
    },
    //Calendar Action
    addCalendarEvent({commit}, event) {
        commit("ADD_CALENDAR_EVENT", event);
    },
    setThemeColor({commit}, color) {
        var reg = /^#([0-9a-f]{3}){1,2}$/i;

        // Primary color.primary
        Promise.resolve(reg.test(color.primary)).then(function (value) {
            if (value) {
                // localStorage.setItem("primary_color", color.primary);
                commit("SET_PRIMARY_COLOR_ARRAY", hexToRgb(color.primary).rgbArray);
                commit("SET_PRIMARY_COLOR", hexToRgb(color.primary).rgbText);
            }
        });
        // Secondary color.secondary
        Promise.resolve(reg.test(color.secondary)).then(function (value) {
            if (value) {
                // localStorage.setItem("secondary_color", color.secondary);
                commit("SET_SECONDARY_COLOR_ARRAY", hexToRgb(color.secondary).rgbArray);
                commit("SET_SECONDARY_COLOR", hexToRgb(color.secondary).rgbText);
            }
        });
    },
    setWidthInner({commit}, width) {
        commit('SET_WIDTH_INNER', width)
    }
}
const getters = {
    theme(state) {
        return state.Theme;
    }
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

