import service from "./index";
import {store} from "@/store";
import functionsCommon from "@/common/functions";

const resourcePath = "/authentication";

function setUserAllCredit(response) {
    store.dispatch('profile/setURLTracking2PRO', response?.url_tracking_premium ? response?.url_tracking_premium : 0)
    store.dispatch('profile/setCredit', response?.sms_unit ? response?.sms_unit : 0)
    store.dispatch('profile/setURLTracking', response?.url_tracking ? response?.url_tracking : 0)
}

export default {
    login(data) {
        return service.apiAuth.post(`${resourcePath}`, data).then((resp) => {
            if (!resp?.data?.re_verify_status) {
                localStorage.setItem("token", resp.data.accessToken);
                localStorage.setItem("refresh", resp.data.refreshToken);
                // localStorage.setItem("profile", JSON.stringify(resp.data?.customer));
                localStorage.setItem("permissions", JSON.stringify(resp.data?.customer?.permissions));
                localStorage.setItem('block_modal', true)
                localStorage.setItem('once_modal', false)
                localStorage.removeItem('lang')
                return resp.data;
            } else {
                return resp.data;
                const checkEmailOrPhone = data.email ? functionsCommon.checkFormatEmailOrPhone(data.email) : null
                if (checkEmailOrPhone === 'email') {
                    localStorage.setItem('re-verify', resp.data.accessToken)
                    localStorage.setItem('customer_id', resp.data.customer_id)
                    // window.location.href = '/Check-Verify-Dup'
                }else if (checkEmailOrPhone === 'phone') {
                    return {
                        is_verify_phone: true
                    }
                }else {
                    localStorage.setItem('re-verify', resp.data.accessToken)
                    localStorage.setItem('customer_id', resp.data.customer_id)
                    // window.location.href = '/Check-Verify-Dup'
                }
            }

        }).catch((err) => {
            throw err;
        });
    },
    logout(id) {
        return service.apiAuth.delete(`${resourcePath}/${id}`).then((resp) => {
            return resp.data;
        }).catch((err) => {
            throw err;
        });
    },
    viewContact(data) {
        return service.apiAuth.post(`${resourcePath}/${data.group_id}/search/${data.uid}`).then((resp) => {
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },
    getCustomerProfile(setUserCredit = false) {
        return service.apiAuth.get(`/customer/get-profile`).then(resp => {
            if (setUserCredit) {
                setUserAllCredit(resp?.data)
            }
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
}
