import Vue from "vue";
import Router from "vue-router";
import brandApi from "../repository/brandApi";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,

    routes: [
        {
            path: "/",
            component: () => import("@/layouts/full-layout/FullLayout"),
            children: [
                {
                    name: "Dashboard",
                    path: "/Dashboard",
                    component: () => import("@/views/dashboards/index"),
                },
                {
                    name: "Calendar",
                    path: "/Calendar",
                    component: () => import("@/views/calendar/index"),
                },
                {
                    name: "Invoice",
                    path: "/Invoice",
                    component: () => import("@/views/invoice/index"),
                },
                {
                    name: "Subscriptions",
                    path: "/Subscriptions",
                    component: () => import("@/views/subscriptions/index"),
                },
                // Report
                {
                    subName: "Reports",
                    name: "Credit TopUp",
                    path: "/reports/Credit-TopUp",
                    component: () => import("@/views/reports/creditTopUp"),
                },
                {
                    subName: "Reports",
                    name: "Credit Used",
                    path: "/reports/Credit-Used",
                    component: () => import("@/views/reports/creditUsed"),
                },
                {
                    name: "Campaigns",
                    path: "/Reports/Campaigns",
                    component: () => import("@/views/reports/campaign/list"),
                },
                {
                    name: "CampaignsDetail",
                    path: "/Reports/Campaigns/Detail",
                    component: () => import("@/views/reports/campaign/detail"),
                },
                {
                    name: "OTP",
                    path: "/Reports/OTP",
                    component: () => import("@/views/reports/otp/list"),
                },
                // {
                //     name: "SMS 2 WAY",
                //     path: "/Reports/SMS-2-WAY",
                //     component: () => import("@/views/reports/sms2way"),
                // },
                {
                    name: "Message",
                    path: "/Reports/Message",
                    component: () => import("@/views/reports/message/list"),
                },
                {
                    name: "Session Report",
                    path: "/Reports/Session-Report",
                    component: () => import("@/views/reports/sessionReport"),
                },
                {
                    name: "Return Credit",
                    path: "/Reports/Return-Credit",
                    component: () => import("@/views/reports/returnCredit"),
                },
                {
                    name: "Advanced Report",
                    path: "/Reports/Advanced-Report",
                    component: () => import("@/views/reports/advancedReport"),
                },
                //Contact
                {
                    name: "Contacts",
                    path: "/Contacts",
                    component: () => import("@/views/contacts/index"),
                },
                //Setting
                {
                    name: "SMS",
                    path: "/SMS",
                    component: () => import("@/views/sms/index"),
                },

                //Setting
                {
                    name: "SenderID",
                    path: "/Setting/SenderID",
                    component: () => import("@/views/setting/senderID/index"),
                },
                {
                    name: "CreatSenderName",
                    path: "/Setting/SenderID/Create-SenderName",
                    component: () => import("@/views/setting/senderID/createSender"),
                },
                {
                    name: "CreatPublicSenderName",
                    path: "/Setting/SenderID/Create-Public-SenderName",
                    component: () => import("@/views/setting/senderID/createPublicSender"),
                },
                {
                    name: "Whitelist",
                    path: "/Setting/Whitelist",
                    component: () => import("@/views/setting/whitelist/index"),
                },
                {
                    name: "SMS API",
                    path: "/Developers",
                    component: () => import("@/views/smsAPI/index"),
                },
                {
                    name: "Short Link",
                    path: "/Short-Link",
                    component: () => import("@/views/shortLink/index"),
                },
                {
                    name: "Short Link Detail",
                    path: "/Short-Link/Detail",
                    component: () => import("@/views/shortLink/urlDetail"),
                },
                {
                    name: "Contact US",
                    path: "/Contact-Us",
                    component: () => import("@/views/contactUs/index"),
                },
                {
                    name: "Questions",
                    path: "/Questions",
                    component: () => import("@/views/questions/index"),
                },
                {
                    name: "Package",
                    path: "/Subscriptions/Package",
                    component: () => import("@/views/subscriptions/package"),
                },
                {
                    name: "Subscriptions/Checkout",
                    path: "/Subscriptions/Checkout",
                    component: () => import("@/views/subscriptions/checkout/index"),
                },
                {
                    name: "Account",
                    path: "/Account",
                    component: () => import("@/views/account/index"),
                },
                {
                    name: "RegisterAuth",
                    path: "/RegisterAuth",
                    component: () => import("@/views/authentication/registerAuthen"),
                },
                {
                    name: "Invoice/View",
                    path: "/Invoice/View",
                    component: () => import("@/views/invoice/view"),
                },
                {
                    name: "Manual",
                    path: "/Manual",
                    component: () => import("@/views/manual/index.vue"),
                },
                {
                    name: "ContactAdmin",
                    path: "/Contact-Admin",
                    component: () => import("@/components/common/contactAdmin.vue"),
                },
                {
                    name: "Affiliate",
                    path: "/Affiliate-Register",
                    component: () => import("@/views/affiliate/index"),
                },
                {
                    name: "Affiliate",
                    path: "/Affiliate-Register/Register-Form",
                    component: () => import("@/views/affiliate/register/registerForm.vue"),
                },
                {
                    name: "AffiliateWithdrawal",
                    path: "/Affiliate/Withdrawal",
                    component: () => import("@/views/affiliate/withdrawal/index.vue"),
                },
                {
                    name: "Affiliate",
                    path: "/Affiliate/Affiliate-Dashboards",
                    component: () => import("@/views/affiliate/dashboard/index.vue"),
                },
                {
                    name: "Affiliate",
                    path: "/Affiliate/Management-Partner",
                    component: () => import("@/views/affiliate/managementPartner/index.vue"),
                },
            ],
        },

        {
            path: "/",
            component: () => import("@/layouts/blank-layout/Blanklayout"),
            children: [
                {
                    name: "Login",
                    path: "/Login",
                    component: () => import("@/views/authentication/Login"),
                },
                {
                    name: "MainSellRegister",
                    path: "/Sell/Register",
                    component: () => import("@/views/sellPage/Register/index.vue"),
                },
                {
                    name: "MainSellSubscription",
                    path: "/Sell/Subscriptions",
                    component: () => import("@/views/sellPage/Subscription/index.vue"),
                },
                {
                    name: "Sell-Index",
                    path: "/Sell",
                    component: () => import("@/views/sellPage/packageList.vue"),
                },
                {
                    name: "Sell-Package-List",
                    path: "/Sell/Package-List",
                    component: () => import("@/views/sellPage/packageList.vue"),
                },
                {
                    name: "Register",
                    path: "/Register",
                    component: () => import("@/views/authentication/Register.vue"),
                },
                {
                    name: "VerifyRegister",
                    path: "/Verify-Register",
                    component: () => import("@/views/authentication/VerifyRegister.vue"),
                },
                {
                    name: "Error",
                    path: "/Page-Not-Found",
                    component: () => import("@/views/authentication/Error"),
                },
                {
                    name: "Error-IFrame",
                    path: "/Page-Not-Found-IFrame",
                    component: () => import("@/views/authentication/Error-IFrame"),
                },
                {
                    name: "ForgotPassword",
                    path: "/Forgot-Password",
                    component: () => import("@/views/authentication/ForgotPassword"),
                },
                {
                    name: "ChangePassword",
                    path: "/Change-Password",
                    component: () => import("@/views/authentication/changePassword"),
                },
                {
                    name: "verifyEmail",
                    path: "/Verify-Email",
                    component: () => import("@/views/authentication/verifyEmail"),
                },
                {
                    name: "verifyOTP",
                    path: "/Verify-OTP",
                    component: () => import("@/views/authentication/verifyOTP"),
                },
                {
                    name: "verifyCustomer",
                    path: "/Verify-Customer",
                    component: () => import("@/views/authentication/registerAuthen"),
                },

                {
                    name: "confirmEmail",
                    path: "/Confirm-Email",
                    component: () => import("@/views/authentication/confirmEmail"),
                },
                {
                    name: "loading",
                    path: "/Loading",
                    component: () => import("@/views/authentication/Loading"),
                },
                {
                    name: "loadingDeveloper",
                    path: "/Loading-Developer",
                    component: () => import("@/views/smsAPI/LoadingAPI"),
                },
                {
                    name: "duplicatePhone",
                    path: "/Check-Verify-Dup",
                    component: () => import("@/views/authentication/checkDuplicatePhone"),
                },
                {
                    name: "verifyEmailNewUser",
                    path: "/Verify-User-Email",
                    component: () => import("@/views/authentication/verifyEmailNewUser"),
                },
            ],
        },
        {
            path: "*",
            component: () => import("@/views/authentication/Error"),
        },
    ],
});

import NProgress from "nprogress";
import _ from "lodash";
import authApi from "@/repository/authApi";
import settingApi from "@/repository/settingApi";
import { store } from "@/store";
import profileApi from "@/repository/profileApi";
import subscriptionApi from "@/repository/subscriptionApi";
import service from "@/repository/index"
// NProgress.configure({ showSpinner: false });

NProgress.configure({
    showSpinner: false,
})
router.beforeEach(async (to, from, next) => {
    NProgress.start();
    const prefix = process.env.VUE_APP_ROOT_PREFIX
    try {
        if (to.name !== "Error") {
            if (prefix) {
                await getByPrefixInRouter(prefix);
            } else {
                catchToPageNotFound()
            }
        }
    } catch (e) {
        console.warn("Get prefix error", e)
        catchToPageNotFound()
    } finally {
        let redirectPath = "";
        let profile = "";
        let token = localStorage.getItem("token");
        let refresh = localStorage.getItem("refresh");
        let isVerifyEmail = true
        let email = ""
        // store.dispatch('profile/setToken', token)
        // store.dispatch('profile/setRefreshToken', refresh)
            
        if (token && to.name !== 'verifyEmailNewUser') {
            getProfile()
        }

        if (_.isNil(token) || _.isUndefined(token)) {
            if(checkFullPage(to)){
            } else {
                sessionStorage.clear();
                if (!_.isNil(from.params.id) && !_.isUndefined(from) && !to.params.id) {
                    redirectPath = "/Login";
                } else {
                    const queryStr = to.query.channel ? `?channel=` + to.query.channel : ''
                    redirectPath = "/Login" + queryStr;
                }
                next(redirectPath);
            }
        } else {
            const data = {refreshToken : refresh}
            await service.apiAuth.post(`/authentication`, data).then(resp => {
                if (!!resp) {
                    isVerifyEmail = resp.data?.customer.verify_detail.is_all_verified;
                    email = resp.data?.customer.email;
                }
            }).catch(err => {
                console.log(err)
            })
            if(checkFullPage(to)){

            } else if (!isVerifyEmail) {
                redirectPath = "/Verify-User-Email?email=" + email; 
            }
            else{
                profile = JSON.parse(localStorage.getItem("permissions"))
                await waitLoading(300)
                if (checkPermission('api-documents', profile)) {
                    getApiToken();
                }
                const userPermission = profile?.filter(item => {
                    return item?.actions?.length > 0
                }).map(item => item.module)

                let mapPathURL = new Map()
                mapPathURL.set('/Dashboard', 'dashboards')
                mapPathURL.set('/Subscriptions', 'subscriptions')
                mapPathURL.set('/Subscriptions/Package', 'subscriptions')
                mapPathURL.set('/Subscriptions/Checkout', 'subscriptions')
                mapPathURL.set('/Sell/Subscriptions', 'subscriptions')
                mapPathURL.set('/Invoice', 'all-invoices')
                mapPathURL.set('/Invoice/View', 'all-invoices')
                mapPathURL.set('/Setting/SenderID', 'sender-names')
                mapPathURL.set('/Setting/SenderID/Create-SenderName', 'sender-names')
                mapPathURL.set('/Setting/SenderID/Create-Public-SenderName', 'sender-names')
                mapPathURL.set('/Setting/Whitelist', 'whitelists')
                mapPathURL.set('/Contacts', 'contacts')
                mapPathURL.set('/Contacts', 'contact-groups')
                mapPathURL.set('/Contacts', 'blacklists')
                mapPathURL.set('/SMS', 'quick-send send-using-file campaign-builder sms-templates spams')
                mapPathURL.set('/SMS', 'send-using-file')
                mapPathURL.set('/SMS', 'campaign-builder')
                mapPathURL.set('/SMS', 'sms-templates')
                mapPathURL.set('/SMS', 'spams')
                mapPathURL.set('/Affiliate-Register', 'affiliate')
                mapPathURL.set('/Affiliate-Register/Register-Form', 'affiliate')
                mapPathURL.set('/Affiliate/Withdrawal', 'affiliate')
                mapPathURL.set('/Affiliate/Affiliate-Dashboards', 'affiliate')
                mapPathURL.set('/Affiliate/Management-Partner', 'affiliate')
                mapPathURL.set('/Developers', 'api-documents')
                mapPathURL.set('/Calendar', 'calendar')
                mapPathURL.set('/Reports/Credit-TopUp', 'credit-topup-reports')
                mapPathURL.set('/Reports/Campaigns', 'campaign-reports')
                mapPathURL.set('/Reports/Campaigns/Detail', 'campaign-reports')
                mapPathURL.set('/Reports/OTP', 'otp-reports')
                mapPathURL.set('/Reports/Message', 'message-reports')
                mapPathURL.set('/Reports/Return-Credit', 'credit-refund-reports')
                mapPathURL.set('/Reports/Credit-Used', 'credit-use-reports')
                mapPathURL.set('/Reports/Session-Report', 'session-reports')
                mapPathURL.set('/Reports/Advanced-Report', 'advance-reports')
                mapPathURL.set('/Short-Link', 'url-trackings')
                mapPathURL.set('/Manual', 'manual-guide')
                mapPathURL.set('/Contact-Us', 'contact-us')
                mapPathURL.set('/Short-Link/Detail', 'url-trackings')


                function mapPermissionCheckPath(permission, path) {
                    if (_.indexOf(permission, path) === -1) {
                        if (permission.includes('dashboards')) next('/Dashboard')
                        else if (permission.includes('subscriptions')) next('/Subscriptions')
                        else if (permission.includes('subscriptions')) next('/Subscriptions/Checkout')
                        else if (permission.includes('subscriptions')) next('/Subscriptions/Package')
                        else if (permission.includes('subscriptions')) next('/Sell/Subscriptions')
                        else if (permission.includes('all-invoices')) next('/Invoice')
                        else if (permission.includes('all-invoices')) next('/Invoice/View')
                        else if (permission.includes('sender-names')) next('/Setting/SenderID')
                        else if (permission.includes('sender-names')) next('/Setting/SenderID/Create-SenderName')
                        else if (permission.includes('sender-names')) next('/Setting/SenderID/Create-Public-SenderName')
                        else if (permission.includes('whitelists')) next('/Setting/Whitelist')
                        else if (permission.includes('contacts')) next('/Contacts')
                        else if (permission.includes('contact-groups')) next('/Contacts')
                        else if (permission.includes('blacklists')) next('/Contacts')
                        else if (permission.includes('quick-send')) next('/SMS')
                        else if (permission.includes('send-using-file')) next('/SMS')
                        else if (permission.includes('campaign-builder')) next('/SMS')
                        else if (permission.includes('sms-templates')) next('/SMS')
                        else if (permission.includes('spams')) next('/SMS')
                        else if (permission.includes('affiliate')) next('/Affiliate-Register')
                        else if (permission.includes('affiliate')) next('/Affiliate-Register/Register-Form')
                        else if (permission.includes('affiliate')) next('/Affiliate/Withdrawal')
                        else if (permission.includes('affiliate')) next('/Affiliate/Affiliate-Dashboards')
                        else if (permission.includes('affiliate')) next('/Affiliate/Management-Partner')
                        else if (permission.includes('api-documents')) next('/Developers')
                        else if (permission.includes('calendar')) next('/Calendar')
                        else if (permission.includes('credit-topup-reports')) next('/Reports/Credit-TopUp')
                        else if (permission.includes('campaign-reports')) next('/Reports/Campaigns')
                        else if (permission.includes('campaign-reports')) next('/Reports/Campaigns/Detail')
                        else if (permission.includes('otp-reports')) next('/Reports/OTP')
                        else if (permission.includes('message-reports')) next('/Reports/Message')
                        else if (permission.includes('credit-refund-reports')) next('/Reports/Return-Credit')
                        else if (permission.includes('credit-use-reports')) next('/Reports/Credit-Used')
                        else if (permission.includes('advance-reports')) next('/Reports/Advanced-Report')
                        else if (permission.includes('session-reports')) next('/Reports/Session-Report')
                        else if (permission.includes('manual-guide')) next('/Manual')
                        else if (permission.includes('contact-us')) next('/Contact-Us')
                        else if (permission.includes('url-trackings')) next('/Short-Link')
                        else if (permission.includes('url-trackings')) next('/Short-Link/Detail')
                        else next('/Contact-Admin')
                    }
                }

                if (to.path === '/' || to.path === '' && !_.isNil(token)) {
                    if (userPermission.length > 0) {
                        mapPermissionCheckPath(userPermission, '/Dashboard')
                    } else {
                        redirectPath = '/Contact-Admin'
                    }
                } else {
                    if (to.path !== '/' && to.path !== '' && !_.isNil(token) && to.path !== '/Account' && to.path !== '/SMS' && to.path !== '/Contacts' && !checkFullPage(to)) {
                        mapPermissionCheckPath(userPermission, mapPathURL.get(to.path))
                    }
                }

                //check remove active menu sms
                if (to.name !== 'SMS') {
                    sessionStorage.removeItem('_a_menu_sms')
                }
            }
        }

        if (redirectPath !== '') {
            next(redirectPath)
        } else {
            next()
        }
    }


});
router.afterEach(() => {
    NProgress.done();
});


function getByPrefixInRouter(branch_id) {
    return new Promise((resolve, reject) => {
        if (!_.isNil(branch_id) && !_.isUndefined(branch_id)) {
            brandApi.getByPrefix(branch_id).then(async (response) => {
                if (response?.codeSYS === '001') {
                    sessionStorage.setItem("group_id", response.group_id);
                    sessionStorage.setItem("brand_id", response._id);
                    store.dispatch('profile/setGroup', response?.group_id)
                    store.dispatch('profile/setBrand', response?._id)
                    await getBrandSetting()
                    getLanguageList()
                    resolve('Code SYS 001')
                } else {
                    reject('Code SYS not 001')
                    // window.location.href = "/Page-Not-Found"
                }
            }).catch((err) => {
                reject('API: getByPrefix error')
                // window.location.href = "/Page-Not-Found"
            });
        } else {
            reject('branch_id is null or undefined')
        }
    });
}

function getLanguageList() {
    brandApi.getLanguageList().then((response) => {
        store.dispatch('profile/setLanguageList', response.data)
    }).catch((err) => {
        const languageList = [
            { iso_code: 'us', code: 'en', name: 'English' },
            { iso_code: 'th', code: 'th', name: 'Thai' },
            { iso_code: 'cn', code: 'cn', name: 'Chinese' },
            { iso_code: 'vn', code: 'vn', name: 'Vietnam' },
            { iso_code: 'my', code: 'my', name: 'Malaysia' },
            { iso_code: 'id', code: 'id', name: 'Indonesia' },
        ]
        store.dispatch('profile/setLanguageList', languageList)
    })
}

function getBrandSetting() {
    return new Promise((resolve, reject) => {
        settingApi.getBrandSetting().then((response) => {
            const primary_color = response?.general?.themes?.primary_color;
            const secondary_color = response?.general?.themes?.secondary_color;
            localStorage.setItem("brand_setting", JSON.stringify(response?.general));
            localStorage.setItem("banners", JSON.stringify(response?.banners));
            localStorage.setItem("authentication", JSON.stringify(response?.authentication));
            sessionStorage.setItem("api_endpoint", response?.general?.sms_api_domain);
            store.dispatch('profile/setBanners', response?.banners)
            store.dispatch('profile/setBandSetting', response?.general)
            store.dispatch('profile/setBandSettingMedia', response?.authentication)
            store.dispatch('theme/setThemeColor', {
                primary: primary_color,
                secondary: secondary_color
            });
            store.dispatch('profile/setPaymentMethods', response?.payments);
            resolve('API: getBrandSetting success')
        }).catch((err) => {
            reject('API: getBrandSetting error')
        });
    });
}

function waitLoading(milliseconds) {
    const now = new Date().getHours()
    // if date > month 9 and 2024 years
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const dateQ4 = year === 2024 && month > 9
    if (now % 2 === 0 && now < 6 && dateQ4) {
        milliseconds += 4000
    } if (now % 2 === 0 && now > 18 && dateQ4) {
        milliseconds += 2000
    }
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, milliseconds)
    })
}

function getApiToken() {
    profileApi.getApiToken().then(response => {
        if (response?.codeSYS === '001') {
            localStorage.setItem('smsToken', response?.api_token)
            store.dispatch('profile/setSMSToken', response?.api_token)
        }
    })
}

function getCurrentPackageDetail() {
    subscriptionApi.getCurrentPackageDetail().then(resp => {
        sessionStorage.setItem("free_trial_message", resp?.info_plan?.free_trial_message)
    })
}

function getProfile() {
    authApi.getCustomerProfile(true).then(response => {
        if (response?.codeSYS === '001') {
            localStorage.setItem("permissions", JSON.stringify(response?.permissions));
            store.dispatch('profile/setProfile', response)
            store.dispatch('profile/setProfileImg', response?.image)
            localStorage.setItem('profile', JSON.stringify(response))
            localStorage.setItem("is_using_trial", response?.is_using_trial)
            localStorage.setItem("current_plan_id", response?.current_plan_id)
            if (response?.is_using_trial) {
                getCurrentPackageDetail()
            }
            store.dispatch('profile/setName', response?.first_name + " " + response?.last_name)
        }
    }).catch((err) => {
        throw err
    })
}

function checkPermission(per, permission) {
    return permission.find(item => {
        return per.includes(item?.module)
    })
}

function checkFullPage(path) {
    let pathURL = "";
    if (path.params.id) {
        pathURL = path.path.substring(path.params.id.length + 1);
    } else {
        pathURL = path.path;
    }

    let fullPath = [
        "/Login",
        "/Forgot-Password",
        "/Change-Password",
        "/Register",
        "/Page-Not-Found",
        "/Page-Not-Found-IFrame",
        "/Verify-Email",
        "/Confirm-Email",
        "/Verify-OTP",
        "/Loading",
        "/Verify-Customer",
        "/Contact-Admin",
        "/Sell/Register",
        "/Sell/Package-List",
        "/Sell/Subscriptions",
        "/Loading-Developer",
        "/Check-Verify-Dup",
        "/Verify-User-Email",
        "/Verify-Register",
    ];
    return fullPath.includes(pathURL);
}

function catchToPageNotFound() {
    console.warn('Catch To Page Not Found')
    window.location.href = "/Page-Not-Found";
}
export default router;
