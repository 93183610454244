import axios from "axios";
import * as jwt from "jsonwebtoken"
import router from "@/router/Router";
import {BToast} from 'bootstrap-vue'
import {store} from "@/store";

const apiAuth = axios.create({
    baseURL: '/api',
});
const apiSms2pro = axios.create({
    baseURL: '/sms-api',
});

let isTokenRefreshing = false
let lang = localStorage.getItem('lang') || JSON.parse(localStorage.getItem('profile'))?.locale;

function interceptor(config) {
    ``
    config.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    config.defaults.headers.common["Accept-Language"] = lang
    config.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('token');
            const groupId = sessionStorage.getItem("group_id") ? sessionStorage.getItem("group_id") : "";
            const brandId = sessionStorage.getItem("brand_id") ? sessionStorage.getItem("brand_id") : "";

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            config.headers["x-group-signature"] = groupId;
            config.headers["x-brand-signature"] = brandId;
            config.headers["Accept-Language"] = store.getters["profile/getLanguage"] || lang;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    config.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                if (!isTokenRefreshing) {
                    originalRequest._retry = true;
                    isTokenRefreshing = true;
                    const refreshToken = {
                        refreshToken: localStorage.getItem('refresh')
                    }
                    try {
                        const response = await axios.post(`/api/authentication`, refreshToken)
                        const new_access_token = response.data.accessToken;
                        const new_refresh_token = response.data.refreshToken;

                        setAccessToken(new_access_token);
                        setRefreshToken(new_refresh_token);

                        originalRequest.headers['Authorization'] = `Bearer ${new_access_token}`;
                        return interceptor(originalRequest);
                    } catch (_error) {
                        if (_error?.response?.data?.code === 401) {
                            if (window.location.pathname === '/Login' || window.location.pathname === '/Register' || window.location.pathname === '/Verify-User-Email' || window.location.pathname === '/Verify-Register') {
                                return Promise.reject(_error);
                            } else {
                                localStorage.clear();
                                sessionStorage.clear();
                                router.push(`Login`).catch(() => {
                                })
                            }
                        }
                        // return Promise.reject(_error);
                    } finally {
                        isTokenRefreshing = false;
                    }
                }
                return Promise.reject(error);
            } else if (error.response.status === 429) {
                let bootStrapToaster = new BToast();
                bootStrapToaster.$bvToast.toast("Too many requests, please try again later.", {
                    title: 'Notice!',
                    toaster: "b-toaster-top-right",
                    solid: true,
                    variant: 'danger',
                    appendToast: false
                })
            }
            return Promise.reject(error);
        }
    );
}

interceptor(apiAuth);
interceptor(apiSms2pro);

function setAccessToken(access_token) {
    localStorage.setItem('token', access_token)
}

function setRefreshToken(refresh_token) {
    localStorage.setItem('refresh', refresh_token)
}

export default {
    apiAuth,
    apiSms2pro,
};