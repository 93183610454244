import _ from 'lodash'

const state = {
    tagList: [],
    headerList: [],
}

const actions = {
    setTagList({commit}, data) {
        commit('SET_DATA_CUSTOMER_LIST', data)
    },
    setHeaderList({commit}, data) {
        commit('SET_DATA_HEADER_LIST', data)
    },

}
const mutations = {
    'SET_DATA_CUSTOMER_LIST'(state, data) {
        state.tagList = data
    },
    'SET_DATA_HEADER_LIST'(state, data) {
        state.headerList = data
    },
}

const getters = {
    getTagList: (state) => {
        const _mapList = (item) => {
            return {
                value: _.get(item, "value", null),
                en: _.get(item, "name", ""),
                th: _.get(item, "name", ""),
                cn: _.get(item, "name", ""),
                vn: _.get(item, "name", ""),
                my: _.get(item, "name", ""),
                id: _.get(item, "name", ""),
            };
        }
        return _.map(state.tagList, (item) => _mapList(item))

    },
    getHeaderList: (state) => {
        const _mapList = (item) => {
            return {
                value: _.get(item, "value", null),
                text: _.get(item, "value", ""),
            };
        }
        return _.map(state.headerList, (item) => _mapList(item))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}