import moment from "moment";
import _ from "lodash";
import emojiStrip from "emoji-strip"
import tr from "vue2-datepicker/locale/es/tr";

export default {
    resizeImage(file, callback) {
        const reader = new FileReader();

        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const targetFileSizeMB = 10;
                const maxWidth = 1280;
                const maxHeight = 1280;

                let newWidth = img.width;
                let newHeight = img.height;

                const originalSizeInMB = file.size / (1024 * 1024);

                if (originalSizeInMB > targetFileSizeMB) {
                    const scaleFactor = Math.sqrt(targetFileSizeMB / originalSizeInMB);

                    newWidth *= scaleFactor;
                    newHeight *= scaleFactor;
                }

                if (newWidth > maxWidth) {
                    newWidth = maxWidth;
                    newHeight = (img.height * maxWidth) / img.width;
                }

                if (newHeight > maxHeight) {
                    newWidth = (img.width * maxHeight) / img.height;
                    newHeight = maxHeight;
                }

                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                const resizedDataURL = canvas.toDataURL('image/jpeg', 1);

                callback(resizedDataURL);
            };

            img.src = e?.target?.result;
        };

        reader.readAsDataURL(file);
    },
    checkUrl(e) {
        const check = true;

        // Use a regular expression to check if the string starts with 'http://' or 'https://'
        const urlRegex = /^(https?:\/\/)/;

        if (urlRegex.test(e)) {
            return check;
        } else {
            return !check;
        }
    },
    changeDateFormat(date) {
        return date ? moment(date).format("DD/MM/YYYY HH:mm") : "";
    },
    changeDateTimeFormat(date) {
        return date ? moment(date).format("DD/MM/YYYY HH:mm") : "";
    },
    changeDateNotTimeFormat(date) {
        return date ? moment(date).format("DD/MM/YYYY") : "";
    },
    filterMasterData(Arr, value, local = 'en') {
        const filtered = Arr.find(element => element.value === value);
        return filtered ? filtered[local] : value
    },
    removeEmoji(data) {
        return data ? emojiStrip(data) : data
    },
    changeDateFormatValueInput(date) {
        return date ? moment(date).format("YYYY-MM-DD") : "";
    },
    changeDateTimeFormatValueInput(date) {
        let newDate = new Date(date)
        let replaceDate = newDate.toISOString().replace('Z', '')
        return date ? moment(replaceDate).format("YYYY-MM-DD HH:mm") : "";
    },
    changeDateTimeTimezoneValueInput(date) {
        let newDate = new Date(date)
        return date ? moment(newDate).format("YYYY-MM-DD HH:mm") : "";
    },
    telInvalid(phone = "") {
        if (phone !== '' && !_.isNil(phone)) {
            const reg = /^[0-9]*((-|\s)*[0-9])*$/g
            if (!phone.match(reg)) return 'common.telNumOnly'
            if (phone.charAt(0) !== '0' && phone.charAt(0) !== '6') {
                return 'common.telStart'
            } else if ((phone.charAt(1) !== '6' && phone.charAt(1) !== '8' && phone.charAt(1) !== '9')) {
                return 'common.telStart'
            } else if (phone.length < 10) {
                return 'common.telLength'
            } else if (phone.length === 11 && (phone.charAt(0) !== '6' && phone.charAt(1) !== '6')) {
                return 'common.telStart66'
            } else if (phone.length !== 11 && (phone.charAt(0) === '6' && phone.charAt(1) === '6')) {
                return 'common.tel11Length'
            } else if ((phone.charAt(0) === '6' && phone.charAt(1) === '6') && phone.charAt(2) !== '6' && phone.charAt(2) !== '8' && phone.charAt(2) !== '9') {
                return 'common.telFormat'
            } else {
                return 'common.telFormat'
            }
        }
    },
    telState(phone = "") {
        const reg = /^[0-9]*((-|\s)*[0-9])*$/g
        if (phone !== '' && !_.isNil(phone)) {
            return (
                (phone.length == 10 &&
                    phone.charAt(0) === '0' &&
                    (phone.charAt(1) === '6' ||
                        phone.charAt(1) === '8' ||
                        phone.charAt(1) === '9') &&
                    phone.match(reg) !== null) || (phone.length == 11 &&
                    phone.charAt(0) === '6' &&
                    phone.charAt(1) === '6' &&
                    (phone.charAt(2) === '6' ||
                        phone.charAt(2) === '8' ||
                        phone.charAt(2) === '9') &&
                    phone.match(reg) !== null)
            )
        }
    },
    telInvalidCompany(phone = "") {
        if (phone !== '' && !_.isNil(phone)) {
            const reg = /^[0-9]*((-|\s)*[0-9])*$/g
            if (!phone.match(reg)) return 'common.telNumOnly'
            if (phone.charAt(0) !== '0' && phone.charAt(0) !== '6') {
                return 'common.telStart2'
            } else if ((phone.charAt(1) !== '6' && phone.charAt(1) !== '8' && phone.charAt(1) !== '9' && phone.charAt(1) !== '2')) {
                return 'common.telStart2'
            } else if (phone.length < 9 && (phone.charAt(0) === '0' && phone.charAt(1) === '2')) {
                return 'common.tel02Length'
            } else if (phone.length < 10 && (phone.charAt(0) === '0' && phone.charAt(1) !== '2')) {
                return 'common.telLength'
            } else if (phone.length === 11 && (phone.charAt(0) !== '6' && phone.charAt(1) !== '6')) {
                return 'common.telStart66'
            } else if (phone.length !== 11 && (phone.charAt(0) === '6' && phone.charAt(1) === '6')) {
                return 'common.tel11Length'
            }
        }
    },
    telStateCompany(phone = "") {
        const reg = /^[0-9]*((-|\s)*[0-9])*$/g
        if (phone !== '' && !_.isNil(phone)) {
            const nineNumber = ((phone.length == 10 || phone.length == 9) &&
                phone.charAt(0) === '0' &&
                (phone.charAt(1) === '2') &&
                phone.match(reg) !== null)

            // fist number is 0 and second number is 2, 6,8,9
            const tenNumber = (phone.length == 10 &&
                phone.charAt(0) === '0' &&
                (phone.charAt(1) === '2' ||
                    phone.charAt(1) === '6' ||
                    phone.charAt(1) === '8' ||
                    phone.charAt(1) === '9') &&
                phone.match(reg) !== null)

            const elevenNumber = (phone.length == 11 &&
                phone.charAt(0) === '6' &&
                phone.charAt(1) === '6' &&
                (phone.charAt(2) === '6' ||
                    phone.charAt(2) === '8' ||
                    phone.charAt(2) === '9') &&
                phone.match(reg) !== null)
            return (
                nineNumber || tenNumber || elevenNumber
            )
        }
    },
    removeEmptyKey(obj) {
        Object.keys(obj).forEach(key => {
            if (_.isNumber(obj[key])) {
                return
            } else if (_.isNil(obj[key]) || _.isEmpty(obj[key])) {
                delete obj[key]
            }
        })
        return obj
    },
    removeEmptyObjKey(obj) {
        Object.keys(obj).forEach(key => {
            if (_.isNumber(obj[key]) || _.isBoolean(obj[key])) {
                return
            } else if (_.isNil(obj[key]) || _.isEmpty(obj[key])) {
                delete obj[key]
            }
        })
        return obj
    },
    checkNumber(event) {
        let RE = /[0-9]/
        if (!RE.test(event.key)) {
            return event.preventDefault();
        }
    },
    checkNumberNotZero(event) {
        let RE = /[1-9]/
        if (!RE.test(event.key)) {
            return event.preventDefault();
        }
    },
    userActionPermission(key, action) {
        // let permission = store.getters["profile/getUserInfo"].data.permissions
        let filterPermission = permission.filter(item => {
            return item.Permission === key
        }).map(per => {
            return per.Action
        })
        return filterPermission.includes(action)
    },
    changeNameRole(name, vm) {
        switch (name) {
            case 'overall':
                return vm.$t('main.menu.dashboard')
            case 'price_detail':
                return vm.$t('main.menu.package')
            case 'contact_group':
                return vm.$t('main.menu.group')
            case 'campaign':
                return vm.$t('main.menu.campaign')
            case 'device':
                return vm.$t('main.menu.sender')
            case 'server':
                return vm.$t('main.menu.server')
            case 'user_profile':
                return vm.$t('main.menu.profile')
            case 'user_manage':
                return vm.$t('main.menu.member')
            case 'user_permission':
                return vm.$t('main.menu.user')
            case 'credit_refill':
                return vm.$t('main.menu.credit')
            case 'user_contact_group_manage':
                return vm.$t('main.menu.groupManagement')
            case 'package':
                return vm.$t('main.menu.packageManagement')
            case 'exchange_manage':
                return vm.$t('main.menu.exchange')
            case 'transaction':
                return vm.$t('main.menu.transaction')
            case 'report':
                return vm.$t('main.menu.report')
            default :
                return name
        }
    },
    sortThaiDictionary(list, vm) {
        const newList = [...list]
        newList.sort((a, b) => a.Permission.localeCompare(this.changeNameRole(b.Permission, vm), 'th'));
        return newList
    },
    countExpire(number, vm) {
        let month = 30
        let year = 365
        let countMonth = (number / month).toFixed()
        let sum = 0
        if (number <= 30) {
            sum = number + " " + vm.$t('main.package.day')
        } else if (number === 31) {
            sum = 1 + " " + vm.$t('main.package.month')
        } else if (countMonth >= 12) {
            sum = _.round((number / year), 1) + " " + vm.$t('main.package.year')
        } else {
            sum = (number / month) + " " + vm.$t('main.package.month')
        }
        return sum
    },
    isOnlyEnglish(event) {
        if (!/^([a-z0-9A-Z/^.?!@&#+%:;/<>*$'",\[\]\{\}^()-])/.test(event.key)) {
            return event.preventDefault();
        }
    },
    isOnlyTHEnglish(event) {
        if (!/^([a-zA-Zก-๙\.])/.test(event.key)) {
            return event.preventDefault();
        }
    },
    isOnlyTHEnglishSpace(event) {
        if (!/^([a-zA-Zก-๙\s\.])/.test(event.key)) {
            return event.preventDefault();
        }
    },
    isOnlyTHEnglishSpaceNumber(event) {
        if (!/^([a-zA-Zก-๙\s0-9\.])/.test(event.key)) {
            return event.preventDefault();
        }
    },
    isStringOnlyTHEnglish(str) {
        // check if the string is only Thai and English
        return /^[ก-๙a-zA-Z\s]+$/.test(str);
    },
    deleteIsNotTHEnglish(data) {
        const regex = /[^ก-๙a-zA-Z]+/g
        return data.replace(regex, '')
    },
    changeNumberFormat(data) {
        if (data) {
            return parseFloat(data).toLocaleString()
        }
    },
    isOnlyNumBerAndDecimal(event) {
        if (!/^([0-9/^\.])/.test(event.key)) {
            return event.preventDefault();
        }
    },
    isOnlyEnglishNumBer(event) {
        if (!/^([a-z0-9A-Z])/.test(event.key)) {
            return event.preventDefault();
        }
    },
    isOnlyEnglishNumberAndUnderscore(event) {
        if (!/^([a-z0-9A-Z\_])/.test(event.key)) {
            return event.preventDefault();
        }
    },
    hasNull(element) {
        return Object.keys(element).some(
            function (key) {
                return element[key] === null || element[key] === ""
            }
        );
    },
    removeComma(data) {
        return data.replace(/,/g, '-');
    },
    isOnlyTH(event) {
        if (!/^([ก-๙])/.test(event.key)) {
            return event.preventDefault();
        }
    },
    userCheckPermission(permission, type, modules) {
        const userPermission = JSON.parse(localStorage.getItem('permissions'))
        if (type === 'tab') {
            return userPermission.find(item => {
                return permission.includes(item?.module) && item?.actions?.length > 0
            })
        } else {
            return userPermission.find(item => {
                if (item.module === modules) {
                    return item?.actions?.includes(permission)
                }
            })
        }
    },
    brandCheckPermission(permission, moduleList) {
        if (!moduleList) return false
        return moduleList.find(item => {
            return item?.module === permission && item?.display
        })
    },
    addComma(data) {
        return _.isNumber(data) ? data?.toLocaleString() : '-'
    },
    colorButtonStatus(data) {
        switch (data) {
            case 0:
                return 'bg-success';
            case 1:
                return 'bg-warning';
            case 2:
                return 'bg-danger';
            case 3:
                return 'bg-danger';
            case 4:
                return 'bg-danger';
            case 7:
                return 'bg-warning';
            case 8:
                return 'bg-gray';
            case 10:
                return 'bg-danger';
            case 11:
                return 'bg-danger';
            default:
                return
        }
    },
    colorButtonStatusByText(data) {
        switch (data) {
            case 'new':
                return 'bg-primary';
            case 'processing':
                return 'bg-warning';
            case 'active':
                return 'bg-success';
            case 'unpaid':
                return 'bg-grey';
            case 'cancelled':
                return 'bg-danger';
            case 'delivered':
                return 'bg-success';
            case 'pending':
                return 'bg-warning';
            case 'paid':
                return 'bg-success';
            case 'pre-credit-approved':
                return 'bg-info';
            case 'pre-credit-paid':
                return 'bg-success';
            default:
                return ""
        }
    },
    formatTime(seconds, vm) {
        const days = Math.floor(seconds / (3600 * 24)) * 24;
        const remainingSeconds = seconds % (3600 * 24);
        let hours = Math.floor((remainingSeconds / 3600) + days);
        const remainingMinutes = remainingSeconds % 3600;
        const minutes = Math.floor(remainingMinutes / 60);
        const remainingSecondsOutput = remainingMinutes % 60;
        return `${this.padNumber(hours)}:${this.padNumber(
            minutes
        )}:${this.padNumber(remainingSecondsOutput)} ${vm.$t('common.minute')}`;
    },
    padNumber(number) {
        return String(number).padStart(2, "0");
    },
    iconMaskURL(image_name, icon_color) {
        try {
            const color = icon_color ? icon_color : 'var(--primary-color)'
            const image = require(`@/assets/images/icon/${image_name}.svg`)
            if (image) {
                return `background-color: ${color}; mask: url(${image}) no-repeat center / contain; -webkit-mask: url(${image}) no-repeat center / contain;`
            }
        } catch {
        }
    },
    backgroundImage(image_name) {
        try {
            const image = require(`@/assets/images/background/${image_name}`)
            if (image) {
                return `background: url(${image}) no-repeat center / contain; background-size: cover;`
            }

        } catch {
        }
    },
    vat(price, discount, vat) {
        let map_vat = vat === 7 ? 0.07 : 0.03
        let sum_vat = 0
        if (discount) {
            sum_vat = (price - discount) * map_vat
        } else {
            sum_vat = (price * map_vat)
        }
        return sum_vat
    },
    addCurrencyToPrice(price = 0, currency) {
        const toFixed = Number(parseFloat(price).toFixed(2)).toLocaleString('th', {
            minimumFractionDigits: 2
        })
        return currency?.replace('{PRICE}', toFixed)
    },
    convertBase64ToImage(base64) {
        return "data:image/jpg;base64," + base64
    },
    getCookie(c_name) {
        let arr = document.cookie.split(";");
        for (let i = 0; i < arr.length; i++) {
            let arr2 = arr[i].split('==')
            if (arr2[0].trim() === c_name) {
                return unescape(arr2[1].trim());
            }
        }
    },
    deleteCookie(c_name) {
        return document.cookie = c_name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    checkFormatEmailOrPhone(data) {
        const phone = /^[0-9]*((-|\s)*[0-9])*$/g
        if (data.match(phone) && data.length > 0) {
            return 'phone'
        } else {
            return 'email'
        }
    },
    emailState(data) {
        const email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return {
            state: email.test(data),
            invalid: 'login.invalid_email'
        }
    }
}