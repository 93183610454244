import _ from 'lodash'

const state = {
    senderNameList: [],
}

const actions = {
    setSenderList({commit}, data) {
        commit('SET_DATA_SENDER_LIST', data)
    },
}
const mutations = {
    'SET_DATA_SENDER_LIST'(state, data) {
        state.senderNameList = data
    },
}

const getters = {
    getSenderList: (state) => {
        const _mapList = (item) => {
            return {
                value: _.get(item, "sender_name", null),
                text: _.get(item, "sender_name", "") + '  [ ' + _.get(item, "source_type", "") + ' ]',
            };
        }
        return _.map(state.senderNameList, (item) => _mapList(item))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}