const state = {
    confirm_payment: {},
    time: 0
}
const actions = {
    setConfirmPayment({commit}, payload) {
        const now = Date.now()
        commit('SET_TIME_INCOME', now)
        commit('SET_CONFIRM_PAYMENT', payload)
    }
}
const mutations = {
    'SET_CONFIRM_PAYMENT'(state, data) {
        state.confirm_payment = data
    },
    'SET_TIME_INCOME'(state, time) {
        state.time = time
    }
}
const getters = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}