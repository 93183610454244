import service from "./index";

const resourcePath = "/brand";

export default {
    getByPrefix(prefix) {
        return service.apiAuth
            .get(`${resourcePath}/get-by-prefix/${prefix}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getBrandPermissions() {
        return service.apiAuth
            .get(`${resourcePath}/permissions`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getLanguageList() {
        return service.apiAuth
            .get(`/language/list`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    }
};
