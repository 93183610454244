const state = {
    userInfo: {},
    smsToken: '',
    credit: 0,
    urlTracking: 0,
    tracking2PRO: 0,
    smsRoute: false,
    register: {
        firstName: '',
        lastName: '',
        username: '',
        phone: '',
        email: '',
        birthday: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false
    },
    profile: null,
    group_id: "",
    brand_id: "",
    name: "",
    profileImg: "",
    banner: [],
    bandSetting: {},
    bandSettingMedia: {},
    languageList: [],
    language: '',
    theme_setting: {},
    token: '',
    refreshToken: '',
    online_socket: false,
    payment_methods: [],
}

const actions = {
    setSMSRoute({commit}, data) {
        commit('SET_SMS_ROUTE', data)
    },
    setProfile({commit}, data) {
        commit('SET_DATA_PROFILE', data)
    },
    setUserInfo({commit}, data) {
        const userInfo = {
            first_name: data?.customer?.first_name,
            group_id: data?.customer?.group_id,
            image: data?.customer?.image,
            last_name: data?.customer?.last_name,
            locale: data?.customer?.locale,
            sms_unit: data?.customer?.sms_unit,
            brand_id: data?.customer?.brand_id,
        }
        commit('SET_DATA_USER_IN_FO', userInfo)
    },
    setBanners({commit}, payload) {
        commit('SET_BANNER', payload)
    },
    setLanguageList({commit}, payload) {
        commit('SET_LANGUAGE_LIST', payload)
    },
    setLanguage({commit}, payload) {
        commit('SET_LANGUAGE', payload)
    },
    setBandSetting({commit}, payload) {
        commit('SET_BAND_SETTING', payload)
    },
    setBandSettingMedia({commit}, payload) {
        commit('SET_BAND_SETTING_MEDIA', payload)
    },
    setRegisterForm({commit}, payload) {
        commit('SET_REGISTER_FORM', payload)
    },
    setSMSToken({commit}, payload) {
        commit('SET_SMS_TOKEN', payload)
    },
    setCredit({commit}, payload) {
        commit('SET_CREDIT', payload)
    },
    setURLTracking({commit}, payload) {
        commit('SET_URL_TRACKING', payload)
    },
    setURLTracking2PRO({commit}, payload) {
        commit('SET_URL_TRACKING_2PRO', payload)
    },
    setName({commit}, payload) {
        commit('SET_NAME', payload)
    },
    setProfileImg({commit}, payload) {
        commit('SET_PROFILE_IMG', payload)
    },
    setGroup({commit}, payload) {
        commit('SET_GROUP', payload)
    },
    setBrand({commit}, payload) {
        commit('SET_BRAND', payload)
    },
    setToken({commit}, payload) {
        commit('SET_TOKEN', payload)
    },
    setRefreshToken({commit}, payload) {
        commit('SET_REFRESH_TOKEN', payload)
    },
    setOnlineSocket({commit}, payload) {
        commit('SET_ONLINE_SOCKET', payload)
    },
    setPaymentMethods({commit}, payload) {
        commit('SET_PAYMENT_METHOD', payload)
    },
}
const mutations = {
    'SET_SMS_ROUTE'(state, data) {
        state.smsRoute = data
    },
    'SET_DATA_PROFILE'(state, data) {
        state.profile = data
    },
    'SET_DATA_USER_IN_FO'(state, data) {
        state.userInfo = data
    },
    'SET_REGISTER_FORM'(state, data) {
        state.register = data
    },
    'SET_SMS_TOKEN'(state, data) {
        state.smsToken = data
    },
    'SET_CREDIT'(state, data) {
        state.credit = data
    },
    'SET_URL_TRACKING_2PRO'(state, data) {
        state.tracking2PRO = data
    },
    'SET_URL_TRACKING'(state, data) {
        state.urlTracking = data
    },
    'SET_BANNER'(state, data) {
        state.banner = data
    },
    'SET_BAND_SETTING'(state, data) {
        state.bandSetting = data
        state.theme_setting = data.themes
    },
    'SET_BAND_SETTING_MEDIA'(state, data) {
        state.bandSettingMedia = data
    },
    'SET_NAME'(state, data) {
        state.name = data
    },
    'SET_PROFILE_IMG'(state, data) {
        state.profileImg = data
    },
    'SET_GROUP'(state, data) {
        state.group_id = data
    },
    'SET_BRAND'(state, data) {
        state.brand_id = data
    },
    'SET_LANGUAGE_LIST'(state, data) {
        state.languageList = data
    },
    'SET_LANGUAGE'(state, data) {
        state.language = data
    },
    'SET_TOKEN'(state, data) {
        state.token = data
    },
    'SET_REFRESH_TOKEN'(state, data) {
        state.refreshToken = data
    },
    SET_ONLINE_SOCKET(state, data) {
        state.online_socket = data
    },
    SET_PAYMENT_METHOD(state, data) {
        state.payment_methods = data
    },
}

const getters = {
    getSMSRoute: state => state.smsRoute,
    getProfile: state => state.profile,
    getUserInfo: state => state.userInfo,
    getRegisterForm: state => state.register,
    getGroup: state => state.group_id,
    getBrand: state => state.brand_id,
    getCredit: state => state.credit,
    getURLTracking: state => state.urlTracking,
    getTracking2PRO: state => state.tracking2PRO,
    getSMSToken: state => state.smsToken,
    getName: state => state.name,
    getBanner: state => state.banner,
    getBandSetting: state => state.bandSetting,
    getBandSettingMedia: state => state.bandSettingMedia,
    getProfileImg: state => state.profileImg,
    getLanguageList: state => state.languageList,
    getLanguage: state => state.language,
    getSettingTheme: state => state.theme_setting
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}