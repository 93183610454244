<template>
  <div id="main-wrapper">
    <router-view/>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import * as jwt from 'jsonwebtoken'
import {store} from "@/store";
import io from 'socket.io-client';
import service from "@/repository/index";

export default {
  name: 'App',
  computed: {
    ...mapState({
      primary_color: state => state.theme.primaryColor,
      secondary_color: state => state.theme.secondaryColor,
      primary_color_array: state => state.theme.primaryColorRGB,
      secondary_color_array: state => state.theme.secondaryColorRGB,
      theme_ui: state => state.profile.theme_setting,
      // token: state => state.profile.token,
      // refreshToken: state => state.profile.refreshToken
    }),
  },
  watch: {
    primary_color(val) {
      this.sheet.insertRule(`:root{--primary-color:  ${val}}`);
    },
    secondary_color(val) {
      this.sheet.insertRule(`:root{--secondary-color: ${val}}`);
    },
    primary_color_array(val) {
      this.sheet.insertRule(`:root{--primary-color-rgb:  ${val}}`);
    },
    secondary_color_array(val) {
      this.sheet.insertRule(`:root{--secondary-color-rgb: ${val}}`);
    },
    'theme_ui'(val) {
      if (val) {
        this.setColor();
      }
    },
    'token'(newToken) {
      if (!!newToken) {
        this.is_ticker = true
      } else {
        this.is_ticker = false
      }
    },
    'is_ticker'(val) {
      if (!!val) {
        this.socket.open(true);
        clearInterval(this.checkIdleInterval)
        this.checkIdleInterval = setInterval(this.checkIdleTimeout, 1000);
      } else {
        clearInterval(this.checkIdleInterval)
        this.$store.dispatch('profile/setToken', null)
        this.$swal.fire().close()
        this.socket.close(true);
      }
    }
  },
  data() {
    return {
      sheet: null,
      primaryLocal: '',
      secondaryLocal: '',
      intervalId: null,
      isIdle: false,
      idleTimeout: 1800000, // Set your idle timeout in milliseconds (e.g., 30 minutes) 1800000
      lastActivityTime: Date.now(),
      is_ticker: false,
      socket: null,
      socket_url: process.env.VUE_APP_ROOT_SOCKET || 'https://subscription-dev.sms2pro.com/',
      token: null,
      refreshToken: null,
    }
  },
  created() {
    this.socket = io(this.socket_url, {
      transports: ["websocket", 'polling'],
      withCredentials: true
    })
    this.socket.on('connect', () => {
      this.$store.dispatch('profile/setOnlineSocket', true)
    });
    this.socket.on('approve-qr-code-invoice', (data) => {
      this.$store.dispatch('subscription/setConfirmPayment', data)
    });
    this.socket.on('disconnect', () => {
      this.$store.dispatch('profile/setOnlineSocket', false)
    });
    window.onresize = () => {
      this.$store.dispatch('theme/setWidthInner', window.innerWidth)
    }
  },
  mounted() {
    this.token  = localStorage.getItem('token');
    if (!_.isNil(this.token)) {
      this.socket.open(true);
      this.checkIdleInterval = setInterval(this.checkIdleTimeout, 1000); // Check every second
      window.addEventListener('mousemove', this.updateLastActivityTime);
      window.addEventListener('input', this.updateLastActivityTime);
      window.addEventListener('change', this.updateLastActivityTime);
      window.addEventListener('keypress', this.updateLastActivityTime);
    } else {
      clearInterval(this.checkIdleInterval)
      this.$swal.fire().close();
      this.socket.close(true);
    }
  },
  methods: {
    setColor() {
      const primary = this.theme_ui.primary_color
      const secondary = this.theme_ui.secondary_color
      const reg = /^#([0-9a-f]{3}){1,2}$/i;

      if (reg.test(primary) && reg.test(secondary)) {
        if (primary === this.primaryLocal && secondary === this.secondaryLocal && this.primaryLocal && this.secondaryLocal) {
          return;
        }
        this.primaryLocal = primary;
        this.secondaryLocal = secondary;
        this.$store.dispatch('theme/setThemeColor', {
          primary,
          secondary,
        });
      } else {
        // default color
        const primary_color = '#19cbac';
        const secondary_color = '#067f6d';
        this.$store.dispatch('theme/setThemeColor', {
          primary: primary_color,
          secondary: secondary_color
        });
      }
      this.sheet = document.styleSheets[0];
    },
    getRefreshToken(refreshToken) {
      const data = {refreshToken}
      service.apiAuth.post(`/authentication`, data).then(resp => {
        if (!!resp) {
          // store.dispatch('profile/setToken', resp.data.accessToken)
          // store.dispatch('profile/setRefreshToken', resp.data.refreshToken)
          localStorage.setItem('token', resp.data.accessToken)
          localStorage.setItem('refresh', resp.data.refreshToken)
          this.checkIdleInterval = setInterval(this.checkIdleTimeout, 1000);
        }
      }).catch(err => {
        if (err?.response?.status === 401) {
          if (window.location.pathname === '/Login' || window.location.pathname === '/Register' || window.location.pathname === '/Verify-User-Email' || window.location.pathname === '/Verify-Register') {
            clearInterval(this.checkIdleInterval)
          } else {
            this.$swal.fire({
            icon: 'error',
            title: this.$t('common.session_exp'),
            text: this.$t('common.login_again'),
            confirmButtonText: this.$t('login.signIn'),
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
            }).then(result => {
              if (result.isConfirmed) {
                this.updateLastActivityTime()
                clearInterval(this.checkIdleInterval)
                localStorage.clear();
                sessionStorage.clear();
              }
            })
          }
        }
      })
    },
    checkIdleTimeout() {
      this.token = localStorage.getItem('token');
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - this.lastActivityTime;
      let expTime = jwt.decode(this.token)?.exp * 1000
      let diff = expTime - currentTime
      if ((timeSinceLastActivity >= this.idleTimeout) && currentTime >= expTime) {
        clearInterval(this.checkIdleInterval)
        this.$swal.fire({
          icon: 'warning',
          title: this.$t('common.inactivity_idle'),
          text: this.$t('common.click_continue'),
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(result => {
          if (result.isConfirmed) {
            this.refreshToken = localStorage.getItem('refresh');
            this.updateLastActivityTime()
            this.getRefreshToken(this.refreshToken)
          }
        })
      } else if ((timeSinceLastActivity < this.idleTimeout) && diff < 10000) {
        this.refreshToken = localStorage.getItem('refresh');
        clearInterval(this.checkIdleInterval)
        this.getRefreshToken(this.refreshToken)
      } else {
        return true
      }
    },
    updateLastActivityTime() {
      this.lastActivityTime = Date.now();
    },
  },
}
</script>

<style lang="scss">
#main-wrapper {
  font-family: "Sukhumvit Set";
}

body {
  font-family: "Sukhumvit Set";
  color: #586A8F !important;
}

.primary-component {

  /* Use the CSS variable in your SCSS */
  .input-primary,
  .input-secondary {
    width: 80px;
    height: 40px;
    margin: 0 auto;
  }
}

#nprogress .bar {
  background: var(--primary-color) !important;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px !important;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #e0e0e0, 0 0 5px #e0e0e0 !important;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
</style>
