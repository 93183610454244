import service from './index'
import functionsCommon from "@/common/functions";

const resourcePath = '/setting'
export default {
    getLanguageList() {
        return service.apiAuth.get(`/language/list`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getBrandSetting() {
        return service.apiAuth.get(`${resourcePath}/get`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getPayment2c2pList() {
        return service.apiAuth.get(`/credit-payment-2c2p/list`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getDetail2c2pPayment(data) {
        return service.apiAuth.post(`/credit-payment-2c2p/get-url`, data).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    }
}