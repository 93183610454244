import service from './index'
import functionsCommon from "@/common/functions";
import {pack} from "core-js/internals/ieee754";

const resourcePath = '/subscription'
export default {
    getSubscriptionHistoryList(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`${resourcePath}/log/list?${str}`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getTransactionList(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`${resourcePath}/transaction/list?${str}`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getCurrentPackageDetail() {
        return service.apiAuth.get(`${resourcePath}/get-current`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    updateAddressInvoice(form, customer_id) {
        const mapData = {
            first_name: form?.first_name,
            last_name: form?.last_name,
            tax_number: form?.tax_number,
            email: form?.email,
            phone: form?.phone,
            address: form?.address_info?.address,
            sub_district: form?.address_info?.sub_district,
            district: form?.address_info?.district,
            province: form?.address_info?.province,
            zipcode: form?.address_info?.zipcode,
            country: form?.address_info?.country,
            url: form?.url,
            name: form?.name,
            amount: form?.amount,
            is_renew: form?.is_renew,
            is_juristic: form?.juristic_type === 'juristic',
            include_withholding_tax: form?.juristic_type === 'juristic' ? form?.include_withholding_tax : false
        }
        return service.apiAuth.patch(`/customer/update-address-invoice/${customer_id}`, mapData).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    createSubscription(body) {
        const obj = functionsCommon.removeEmptyObjKey(body)
        return service.apiAuth.post(`/subscription/create`, obj).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getBankAccountList() {
        return service.apiAuth.get(`/bank-account/list`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    usePromotionCode(data) {
        const obj_data = functionsCommon.removeEmptyObjKey(data)
        return service.apiAuth.post(`/promotion/use-by-code`, obj_data).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    uploadSlipInvoice(slips, id) {
        return service.apiAuth.patch(`/subscription/upload-slip/${id}`, slips).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getSubscriptionById(id) {
        return service.apiAuth.get(`/subscription/get/${id}`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    activePlanExpire(data, id) {
        const mapData = {
            is_notify: data?.is_notify,
            expires_in: Number(data?.expires_in)
        }
        return service.apiAuth.put(`/customer/active-plan-expires/${id}`, mapData).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    cancelSubscriptionById(data) {
        const map_data = {
            cancel_reasons: data?.cancel_reasons
        }
        return service.apiAuth.patch(`/invoice/cancel/${data.id}`, map_data).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    createSubscriptionCustomPackage(body) {
        const obj = functionsCommon.removeEmptyObjKey(body)
        return service.apiAuth.post(`/subscription/create-custom`, obj).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getPromotionByPlanId(plan_id) {
        return service.apiAuth.get(`/promotion/get-by-plan?plan_id=${plan_id}`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    createQrCodePayment(subscription_id) {
        const map_data = {
            subscription_id
        }
        return service.apiAuth.post(`/scb-payment/generate-qrcode`, map_data).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    checkInQuiryQrcode(subscription_id) {
        const map_data = {
            subscription_id
        }
        return service.apiAuth.post(`/scb-payment/approve-invoice-by-inquiry`, map_data).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
}