import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/index";
import {store} from './store';
import router from "./router/Router";
import "./assets/scss/style.scss";
import App from "./App.vue";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import i18n from './i18n/i18n.js'
import VueThailandAddress from 'vue-thailand-address';
import 'vue-thailand-address/dist/vue-thailand-address.css';
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import VueTippy, {TippyComponent} from "vue-tippy";
import Multiselect from 'vue-multiselect'

Vue.component('multiselect', Multiselect)
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);
Vue.use(VueFeather);
Vue.use(InstantSearch);
Vue.use(VueThailandAddress);
Vue.config.productionTip = false;
new Vue({store, router, i18n, render: (h) => h(App)}).$mount("#app");



