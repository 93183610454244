import service from "./index";
import functionsCommon from "@/common/functions";

const resourcePath = "/customer";
export default {
    otpRegisterSend(data) {
        localStorage.setItem('block_modal', true)
        localStorage.setItem('once_modal', false)
        return service.apiAuth.post(`${resourcePath}/otp-register-send`, data).then((resp) => {
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },
    otpOldUserSend(data) {
        return service.apiAuth.post(`/old-customers/request-verify-otp`, data).then((resp) => {
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },
    getRegisterInfo(data) {
        return service.apiAuth.get(`${resourcePath}/register-info/${data}`).then((resp) => {
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },

    checkOTP(data) {
        return service.apiAuth.post(`${resourcePath}/otp-register-verify`, data).then((resp) => {
            localStorage.setItem("token", resp.data.accessToken);
            localStorage.setItem("refresh", resp.data.refreshToken);
            localStorage.setItem("profile", JSON.stringify(resp.data?.customer));
            localStorage.setItem("permissions", JSON.stringify(resp.data?.customer?.permissions));
            return resp;
        })
            .catch((err) => {
                throw err;
            });
    },
    verifyOTP(data) {
        return service.apiAuth.post(`${resourcePath}/verify-register-otp`, data).then((resp) => {
            localStorage.setItem("token", resp.data.accessToken);
            localStorage.setItem("refresh", resp.data.refreshToken);
            localStorage.setItem("profile", JSON.stringify(resp.data?.customer));
            localStorage.setItem("permissions", JSON.stringify(resp.data?.customer?.permissions));
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },
    requestEmailOTP(data){
        return service.apiAuth.post(`${resourcePath}/request-email-otp`, data).then((resp) => {
            return resp;
        })
        .catch((err) => {
            throw err;
        });
    },
    checkVerifyEmailOTP(data){
        const query = `token=${data.token}&email=${data.email}`
        return service.apiAuth.get(`${resourcePath}/verify-email-otp?${query}`, data).then((resp) => {
            return resp;
        })
        .catch((err) => {
            throw err;
        });
    },
    checkOTPOldUser(data) {
        return service.apiAuth.post(`old-customers/verify-otp`, data).then((resp) => {
            localStorage.setItem("token", resp.data.accessToken);
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },
    signUp(data) {
        return service.apiAuth.post(`${resourcePath}/sign-up`, data).then((resp) => {
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },
    setNewPassword(data) {
        return service.apiAuth.patch(`/old-customers/set-new-password`, data).then((resp) => {
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },
    checkDuplicate(data) {
        return service.apiAuth.post(`${resourcePath}/check-register-duplicate`, data).then((resp) => {
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },
    verifyResetPassword(data) {
        return service.apiAuth.post(`${resourcePath}/verify-reset-password`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    resetPassword(data) {
        return service.apiAuth.post(`${resourcePath}/reset-password`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    resendVerify(data) {
        return service.apiAuth
            .post(`${resourcePath}/request-verify`, data)
            .then((resp) => {
                return resp
            })
            .catch((err) => {
                throw err
            });
    },
    changePassword(data) {
        const profile = JSON.parse(localStorage.getItem("profile"));
        const id = profile._id;
        return service.apiAuth
            .patch(`${resourcePath}/change-password/${id}`, data)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    checkVerifyEmail(data) {
        return service.apiAuth
            .post(`${resourcePath}/check-allow-verify`, data)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    forgotPassword(data) {
        return service.apiAuth.post(`${resourcePath}/request-reset-password`, data)
            .then(resp => {
                return resp
            }).catch(err => {
                throw err
            })
    },
    verifyEmail(token) {
        localStorage.setItem("token", token);
        return service.apiAuth
            .post(`${resourcePath}/verify`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw er;
            })
            .finally(() => {
                localStorage.removeItem("token");
            });
    },
    updateAccountInfo(body, id) {
        const mapData = {
            phone: body?.phone,
            first_name: body?.first_name,
            last_name: body?.last_name,
            image: body?.image,
            locale: body?.locale,
        }
        return service.apiAuth.patch(`/customer/update-account/${id}`, mapData).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    changePasswordCustomerProfile(body) {
        const mapData = {
            old_password: body?.old_password,
            new_password: body?.new_password
        }
        const data = functionsCommon.removeEmptyKey(mapData)
        return service.apiAuth.patch(`/customer/change-password`, data).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    updateCustomerInformation(body, id) {
        const mapData = {
            company: body?.company,
            website: body?.website,
            address: body?.address,
            sub_district: body?.sub_district,
            district: body?.district,
            province: body?.province,
            country: body?.country,
            zipcode: body?.zipcode,
            tax_number: body?.tax_number,
        }
        const data = functionsCommon.removeEmptyKey(mapData)
        return service.apiAuth.patch(`/customer/update-info/${id}`, data).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    registerWithAuth(body, id) {
        return service.apiAuth.patch(`/customer/update-oauth-account/${id}`, body).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    generateApiToken() {
        return service.apiAuth.post(`${resourcePath}/generate-token`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getApiToken() {
        return service.apiAuth.get(`${resourcePath}/api-token`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getPopupStatus(id, status) {
        return service.apiAuth.patch(`${resourcePath}/update-pop-up-notify/${id}`, status)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    updateNotification(id, data) {
        return service.apiAuth.patch(`${resourcePath}/update-notification/${id}`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getMasterSalesList() {
        return service.apiAuth.get(`/customer/master-sales-list`).then(resp => {
            return resp?.data?.data
        }).catch(err => {
            throw err
        })
    },
    verifyOtpRegister(data) {
        return service.apiAuth.post(`/customer/re-verify-register`, data).then(resp => {
            localStorage.setItem("token", resp.data.accessToken);
            localStorage.setItem("refresh", resp.data.refreshToken);
            localStorage.setItem("profile", JSON.stringify(resp.data?.customer));
            localStorage.setItem("permissions", JSON.stringify(resp.data?.customer?.permissions));
            return resp.data;
        }).catch(err => {
            throw err
        })
    },
    checkDuplicatePhoneNumber (data) {
        return service.apiAuth.post(`/customer/check-register-duplicate`, data).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    updateVerifyInfo (data) {
        const payload = functionsCommon.removeEmptyKey(data)
        return service.apiAuth.post(`/verification/update-info`, payload).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getVerifyInfo () {
        return service.apiAuth.get(`/verification/get`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    uploadFileOthers (data) {
        const file = {
            file: data.others
        }
        return service.apiAuth.post(`/verification/update-files`, file).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    checkComparePassword (password) {
        const data = {
            password: password
        }
        return service.apiAuth.post(`${resourcePath}/check-compare-password`, data).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    }
};
