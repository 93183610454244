import Vue from 'vue'
import Vuex from 'vuex'
import theme from "@/store/theme"
import profile from "@/store/profile";
import subscription from "@/store/subscription";
import template from "@/store/template";
import sender from "@/store/sender";

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        theme,
        profile,
        subscription,
        template,
        sender

    },
    strict: false,
});